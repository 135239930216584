import tw, { styled } from "twin.macro";
import { Icon } from "@iconify/react";
import { Link } from "~/components/Base";

type Layouts =
  | "base"
  | "purple"
  | "white"
  | "gradientGreen"
  | "gradientDark"
  | "gradientBlue"
  | "gradientGreen2"
  | "gradientYellow"
  | "gradientPink"
  | "gradientRed"
  | "gradientPurple";

export interface NavLinkType {
  url: string;
  onClick?: () => void;
  label: string;
  isComingSoon?: boolean;
  isVisible?: boolean;
  icon: string;
  isMobile?: boolean;
}
export interface NavLinks {
  [userType: string]: {
    primaryNav?: NavLinkType[];
    secondaryNav?: NavLinkType[];
  };
}

/**
 * Mobile navbar constants
 */

export const MenuIconsNavbarWrapper = tw.nav`fixed top-0 w-full z-40 flex justify-between font-serif items-center p-20 text-white bg-transparent`;

export const NavbarMobileContent = styled.div<{
  $layout: Layouts;
  $isOpen: boolean;
  $isScrolled: boolean;
}>`
  ${tw`w-full h-full flex justify-between items-center px-20 py-20 z-100`}

  ${({ $isScrolled, $layout }) => {
    if ($isScrolled && $layout === "base") return tw`bg-gradientBase `;
    if ($isScrolled && $layout === "gradientGreen") return tw`bg-gradientGreen `;
    if ($isScrolled && $layout === "purple") return tw`bg-gradientPurple `;
    if ($isScrolled && $layout === "white") return tw`bg-white text-blue `;
    if ($isScrolled && $layout === "gradientDark") return tw`bg-gradientDark `;
    if ($isScrolled && $layout === "gradientBlue") return tw`bg-gradientBlue `;
    if ($isScrolled && $layout === "gradientGreen2") return tw`bg-gradientGreen2 `;
    if ($isScrolled && $layout === "gradientYellow") return tw`bg-gradientYellow `;
    if ($isScrolled && $layout === "gradientPink") return tw`bg-gradientPink `;
    if ($isScrolled && $layout === "gradientRed") return tw`bg-gradientRed `;
    if ($isScrolled && $layout === "gradientPurple") return tw`bg-gradientPurple `;
  }}

  ${({ $isOpen }) => $isOpen && tw`bg-gradientBase`}
`;

export const MobileNav = styled.nav<{
  $isScrolled: boolean;
  $layout: Layouts;
  $isOpen: boolean;
  $isPrivate: boolean;
  $isXl: boolean | undefined;
  $isAuthenticated: boolean;
}>`
  ${tw`fixed top-0 w-full px-0 py-0 z-40 flex justify-between font-serif items-center text-white bg-transparent whitespace-nowrap `}

  ${({ $isScrolled, $layout }) => {
    if ($isScrolled && $layout === "base") return tw`bg-gradientBase shadow-xl`;
    if ($isScrolled && $layout === "gradientGreen") return tw`bg-gradientGreen shadow-xl`;
    if ($isScrolled && $layout === "purple") return tw`bg-gradientPurple shadow-xl`;
    if ($isScrolled && $layout === "white") return tw`bg-white text-blue shadow-xl`;
    if ($isScrolled && $layout === "gradientDark") return tw`bg-gradientDark shadow-xl`;
    if ($isScrolled && $layout === "gradientBlue") return tw`bg-gradientBlue shadow-xl`;
    if ($isScrolled && $layout === "gradientGreen2") return tw`bg-gradientGreen2 shadow-xl`;
    if ($isScrolled && $layout === "gradientYellow") return tw`bg-gradientYellow shadow-xl`;
    if ($isScrolled && $layout === "gradientPink") return tw`bg-gradientPink shadow-xl`;
    if ($isScrolled && $layout === "gradientRed") return tw`bg-gradientRed shadow-xl`;
    if ($isScrolled && $layout === "gradientPurple") return tw`bg-gradientPurple shadow-xl`;
  }}

  ${({ $isScrolled, $layout, $isOpen, $isPrivate }) =>
    $isScrolled && !$isPrivate && $layout === "base"
      ? tw`bg-gradientBase box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`
      : $isScrolled &&
        !$isPrivate &&
        $layout === "white" &&
        !$isOpen &&
        tw`bg-white box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`}
      
  ${({ $isOpen }) => $isOpen && tw`bg-transparent background-image[none] bg-opacity-0 shadow-none`}

  ${({ $isAuthenticated, $isPrivate }) => $isAuthenticated && $isPrivate && tw`absolute`}
`;

export const MenuContent = styled.div<{ $isPrivate: boolean }>`
  ${tw`w-full mt-80 mb-25 text-white`}

  ${({ $isPrivate }) => $isPrivate && tw`mt-60 mb-25`}
`;

export const MenuButton = tw.button`z-50`;

export const AccountLinksWrapper = tw.div`pt-15`;

export const MenuContainer = styled.div<{ $isOpen?: boolean }>`
  ${tw`fixed h-full overflow-y-auto invisible opacity-0 flex flex-col justify-between items-start font-serif px-20 pb-30 z-30 inset-0 bg-gradientBase`}
  transition: visibility 400ms ease, opacity 400ms ease;

  ${({ $isOpen }) => $isOpen && tw`visible opacity-100`}
`;

export const ItemDivider = tw.div`w-full height[1px] bg-gradient-to-r from-transparent via-white to-transparent my-10`;

export const ProfilePicture = tw.img`w-25 h-25 rounded-full`;

export const MobileUl = tw.ul`flex flex-col items-start mb-15 sm:mb-30`;

export const MobileLi = tw.li`relative flex items-center w-auto text-21 sm:text-24 font-bold py-13 sm:py-20`;

export const RightMobileContainer = tw.div`items-center flex justify-end gap-6`;

// RW logo
export const BrandLink = styled(Link)`
  ${tw`relative z-30 flex flex-row items-center justify-center lg:transform[translateY: -1rem]`}
`;

/**
 * Desktop navbar constants
 */

export const styledComponent = styled(Icon);

export const Ul = tw.ul`flex items-end px-10 lg:px-20  `;
export const Li = tw.li`relative flex items-end px-10 xl:px-30 font-medium text-16 lg:text-base 2xl:px-20`;

export const AccountLi = tw.li`relative flex items-center w-auto text-base font-normal sm:text-24 py-13 sm:py-20`;

export const RightContainer = tw.div`items-center flex`;

export const NavbarContent = styled.div<{ $isAuthenticated: boolean; $isScrolled: boolean; $isPrivate: boolean }>`
  ${tw`max-w-screen-max-content w-full flex justify-between items-center p-20 lg:(py-40 px-80)`}

  ${({ $isAuthenticated, $isScrolled }) => $isAuthenticated && $isScrolled && tw`relative`}
`;
export const DesktopNav = styled.nav<{
  $layout?: Layouts;
  $isScrolled: boolean;
  $isAuthenticated: boolean;
  $isPrivate: boolean;
}>`
  ${tw`fixed z-20 top-0 left-1/2 transform -translate-x-1/2 w-full hidden md:flex justify-center font-serif items-center text-white `}

  ${({ $layout }) => $layout === "white" && tw`text-blue`}

  ${({ $isAuthenticated, $isPrivate }) => $isAuthenticated && $isPrivate && tw`absolute`}

  ${({ $isPrivate }) => !$isPrivate && tw`fixed`}

  ${({ $isScrolled, $layout, $isPrivate }) => $isScrolled && !$isPrivate && checkLayoutScrolled($layout || "base")}
`;

export const NavbarLink = styled.div`
  ${tw`p-10 py-0 block`}
`;

export const AuthDropdownItem = styled.button`
  ${tw`relative m-0 py-10 text-left rounded hover:(bg-grey bg-opacity-20)`}
`;

export const AuthDropdownWrapper = styled.div<{ $isOpen: boolean }>`
  ${tw`hidden md:(absolute shadow-lg border border-grey5 flex invisible opacity-0 transform translate-y-0 flex-col items-stretch min-width[250px] p-10 h-auto box-content top-40 right-0 bg-white text-black rounded-lg)`}
  transition: opacity 200ms ease, visibility 200ms ease, transform 200ms ease;

  ${({ $isOpen }) => $isOpen && tw`md:(flex visible opacity-100 translate-y-6)`}
`;

export const AuthChipWrapper = styled.div<{ $isOpen: boolean }>`
  ${tw`invisible opacity-0 absolute z-10 top-full transform translate-y-0`}
  transition: visibility 100ms ease, opacity 100ms, transform 100ms ease;

  ${({ $isOpen }) => $isOpen && tw`visible opacity-100 translate-y-10`}
`;

export const AuthProfileIconWrapper = styled.div`
  ${tw`relative w-46 h-46 hover:cursor-pointer`}

  > img {
    ${tw`w-full h-full`}
  }

  > svg {
    ${tw`w-full h-full`}
  }
`;
export const checkLayoutScrolled = (layout: Layouts) => {
  switch (layout) {
    case "base":
      return tw`bg-gradientBase box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "white":
      return tw`bg-white text-blue box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "purple":
      return tw`bg-gradientPurple box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientGreen":
      return tw`bg-gradientGreen box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientDark":
      return tw`bg-gradientDark box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientBlue":
      return tw`bg-gradientBlue box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientGreen2":
      return tw`bg-gradientGreen2 box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientYellow":
      return tw`bg-gradientYellow box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientPink":
      return tw`bg-gradientPink box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientRed":
      return tw`bg-gradientRed box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientPurple":
      return tw`bg-gradientPurple box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    default:
      return tw`bg-gradientBase box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
  }
};

export const navLinks: NavLinks = {
  worker: {
    primaryNav: [
      {
        url: "/worker/edit",
        label: "Migliora il tuo profilo",
        isVisible: true,
        isMobile: true,
        icon: "cil:pencil",
      },
      {
        url: "/worker/candidature",
        label: "Le tue candidature",
        isComingSoon: false,
        isVisible: true,
        icon: "fluent:megaphone-loud-20-regular",
      },
      {
        url: "/worker/offerte-di-lavoro",
        label: "Offerte di lavoro",
        isComingSoon: false,
        isVisible: true,
        icon: "carbon:user-profile",
      },
      {
        url: "https://convivier.com/benvenuti-in-convivier/",
        label: "Academy",
        isComingSoon: false,
        isVisible: true,
        icon: "fluent:book-add-20-regular",
      },
      {
        onClick: () => {
          window.Intercom("show");
        },
        url: "#",
        label: "Parla con noi",
        isComingSoon: false,
        isVisible: true,
        icon: "cil:chat-bubble",
      },
      // {
      //   url: "/worker/boost/info",
      //   label: "Licenza Boost",
      //   isVisible: !boostIsActive,
      //   isComingSoon: false,
      // },
    ],
    secondaryNav: [
      {
        url: "/profilo",
        label: "Migliora il tuo profilo",
        isComingSoon: false,
        isVisible: true,
        icon: "cil:pencil",
      },
      // {
      //   url: "/worker/boost/info",
      //   label: "Boost",
      //   isComingSoon: false,
      //   isVisible: true,
      //   icon: "carbon:growth",
      // },
      {
        url: "/",
        label: "Torna al sito web",
        isComingSoon: false,
        isVisible: true,
        icon: "akar-icons:arrow-back-thick",
      },
    ],
  },
  employer: {
    primaryNav: [
      {
        url: "/employer/job-positions",
        label: "Posizioni aperte",
        isVisible: true,
        icon: "clarity:clipboard-line",
      },
      {
        url: "#",
        label: "I miei dati",
        isComingSoon: true,
        isVisible: false,
        icon: "carbon:user-profile",
      },
    ],
    secondaryNav: [
      // {
      //   url: "/account/edit",
      //   label: "Modifica Password",
      //   isComingSoon: false,
      //   isVisible: true,
      //   icon: "uil:setting",
      // },
      {
        url: "/employer/fatturazione",
        // url: "#",
        label: "Fatturazione",
        isComingSoon: false,
        isVisible: true,
        icon: "ic:outline-payments",
      },
      {
        url: "/",
        label: "Torna al sito web",
        isComingSoon: false,
        isVisible: true,
        icon: "akar-icons:arrow-back-thick",
      },
    ],
  },
  employer_contact: {
    primaryNav: [
      {
        url: "/employer/job-positions",
        label: "Posizioni aperte",
        isVisible: true,
        icon: "clarity:clipboard-line",
      },
      {
        url: "#",
        label: "I miei dati",
        isComingSoon: true,
        isVisible: false,
        icon: "carbon:user-profile",
      },
    ],
    secondaryNav: [
      // {
      //   url: "/account/edit",
      //   label: "Modifica Password",
      //   isComingSoon: false,
      //   isVisible: true,
      //   icon: "uil:setting",
      // },
      {
        url: "/employer/fatturazione",
        // url: "#",
        label: "Fatturazione",
        isComingSoon: false,
        isVisible: true,
        icon: "ic:outline-payments",
      },
      {
        url: "/",
        label: "Torna al sito web",
        isComingSoon: false,
        isVisible: true,
        icon: "akar-icons:arrow-back-thick",
      },
    ],
  },
  public: {
    primaryNav: [
      {
        url: "/cerco-lavoro",
        label: "Cerco lavoro",
        isVisible: true,
        icon: "ps:work-case",
      },
      {
        url: "/employer",
        label: "Cerco staff",
        isVisible: true,
        icon: "ri:user-search-line",
      },
      {
        url: "/responsabilita-sociale",
        label: "Responsabilità sociale",
        isVisible: true,
        icon: "bx:donate-heart",
      },
      {
        url: "/chi-siamo",
        label: "Chi siamo",
        isVisible: true,
        icon: "fluent:people-team-16-regular",
      },
      {
        url: "/diventa-partner",
        label: "Diventa partner",
        isVisible: true,
        isMobile: true,
        icon: "ph:handshake-light",
      },
      {
        url: "/faq",
        label: "FAQ",
        isVisible: true,
        isMobile: true,
        icon: "radix-icons:question-mark-circled",
      },
    ],
    secondaryNav: [],
  },
};
