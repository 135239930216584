import React, { MouseEventHandler, MutableRefObject, RefObject } from "react";
import tw, { styled } from "twin.macro";
import { UserData } from "types";
import { Icon } from "@iconify/react";
import {
  ProfilePicture,
  BrandLink,
  Ul,
  Li,
  RightContainer,
  NavbarContent,
  DesktopNav,
  NavbarLink,
  AuthChipWrapper,
  AuthProfileIconWrapper,
  AuthDropdownItem,
  AuthDropdownWrapper,
  navLinks,
} from "./NavbarConstants";
import { Layouts } from "./Navbar";
import ComingSoonChip from "~/assets/icons/coming-soon-tooltip.svg";
import ProfileAvatarWhite from "~/assets/icons/avatar.svg";
import ProfileAvatarBlue from "~/assets/icons/avatar-blue.svg";
import LogoSVGWhite from "~/assets/logos/restworld-horizontal-white.svg";
import LogoSVGBlue from "~/assets/logos/restworld-horizontal-blue.svg";
import { Link, Button } from "~/components/Base";

interface DesktopNavbarProps {
  isScrolled: boolean;
  layout: Layouts;
  isPrivate: boolean;
  isWhiteLayout: boolean;
  jwt?: string | null;
  userData?: UserData | null;
  isLoading: boolean;
  logout: MouseEventHandler<HTMLButtonElement>;
  activeChip: number | null;
  linkRefs: MutableRefObject<(HTMLLIElement | HTMLButtonElement | null)[]>;
  setOpenChip: Function;
  isProfileDropdownOpen: boolean;
  setIsProfileDropdownOpen: Function;
  dropdownRef: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined;
  secondaryLinkRefs: MutableRefObject<(HTMLLIElement | HTMLButtonElement | null)[]>;
  profileImg: string;
  menuRef: RefObject<HTMLDivElement> | null;
}

export const DesktopNavbar = ({
  jwt,
  userData,
  logout,
  isLoading,
  isScrolled,
  isPrivate,
  isWhiteLayout,
  layout,
  activeChip,
  linkRefs,
  setOpenChip,
  isProfileDropdownOpen,
  setIsProfileDropdownOpen,
  dropdownRef,
  secondaryLinkRefs,
  profileImg,
}: DesktopNavbarProps) => {
  return (
    <DesktopNav
      $layout={layout || "base"}
      $isScrolled={isScrolled}
      $isAuthenticated={!!userData}
      $isPrivate={isPrivate}
    >
      {/* entire navbar container */}
      <NavbarContent $isAuthenticated={!!userData} $isScrolled={isScrolled} $isPrivate={isPrivate}>
        {/* logo link container */}
        <BrandLink
          href={
            !isPrivate
              ? "/"
              : userData?.type === "employer"
              ? "/employer/job-positions"
              : userData?.type === "worker"
              ? "/worker/dashboard"
              : "/"
          }
        >
          {isWhiteLayout ? <LogoSVGBlue tw="h-30 lg:h-44" /> : <LogoSVGWhite tw="h-30 lg:h-44" />}
        </BrandLink>

        {/* navbar's links and buttons container */}
        <RightContainer>
          <Ul>
            {/* non private links */}
            {(!userData || !isPrivate) &&
              !!navLinks &&
              navLinks.public?.primaryNav
                ?.filter((link) => !!link.isVisible && !link.isMobile)
                ?.map((link, i) => (
                  <Li
                    {...(!!link.isComingSoon ? { onClick: () => setOpenChip(i) } : undefined)}
                    {...(!!link.onClick ? { onClick: link.onClick } : undefined)}
                    key={i}
                    ref={(ref) => (linkRefs.current[i] = ref)}
                  >
                    {!!link.isComingSoon || !!link.onClick ? (
                      <NavbarLink tw="cursor-pointer flex items-center">
                        <Icon icon={link.icon} height={24} tw="mb-5 mr-10" /> {link.label}
                      </NavbarLink>
                    ) : (
                      <NavbarLink as={Link} href={link.url} tw="flex items-center">
                        <Icon icon={link.icon} height={24} tw="mb-5 mr-10" /> {link.label}
                      </NavbarLink>
                    )}
                    {link.isComingSoon && (
                      <AuthChipWrapper $isOpen={activeChip === i}>
                        <ComingSoonChip />
                      </AuthChipWrapper>
                    )}
                  </Li>
                ))}
            {/* private links */}
            {!!userData &&
              !!navLinks &&
              isPrivate &&
              navLinks[userData.type]?.primaryNav
                ?.filter((link) => !!link.isVisible && !link.isMobile)
                ?.map((link, i) => (
                  <Li
                    {...(!!link.isComingSoon ? { onClick: () => setOpenChip(i) } : undefined)}
                    {...(!!link.onClick ? { onClick: link.onClick } : undefined)}
                    key={i}
                    ref={(ref) => (linkRefs.current[i] = ref)}
                  >
                    {!!link.isComingSoon || !!link.onClick ? (
                      <NavbarLink tw="cursor-pointer flex items-center">
                        <Icon icon={link.icon} height={24} tw="mb-5 mr-10" /> {link.label}
                      </NavbarLink>
                    ) : (
                      <NavbarLink as={Link} href={link.url} tw="flex items-center">
                        <Icon icon={link.icon} height={24} tw="mb-5 mr-10" /> {link.label}
                      </NavbarLink>
                    )}
                    {link.isComingSoon && (
                      <AuthChipWrapper $isOpen={activeChip === i}>
                        <ComingSoonChip />
                      </AuthChipWrapper>
                    )}
                  </Li>
                ))}
          </Ul>

          {/* navbar right side button */}
          {(!userData || !isPrivate) && (
            <Button as={Link} href="/login" $state={!!layout && layout === "white" ? "activeGreen" : "base"} tw="w-100">
              {isLoading ? "Accedi" : !!jwt && !!userData ? "Profilo" : "Accedi"}
            </Button>
          )}

          {/* dropdown menu authenticated */}
          {isPrivate && !!userData && (
            <AuthProfileIconWrapper onClick={() => setIsProfileDropdownOpen((prev: boolean): boolean => !prev)}>
              {!profileImg ? (
                layout === "white" ? (
                  <ProfileAvatarBlue />
                ) : (
                  <ProfileAvatarWhite />
                )
              ) : (
                <ProfilePicture tw="h-34 w-34" src={profileImg} />
              )}
              <AuthDropdownWrapper $isOpen={isProfileDropdownOpen} ref={dropdownRef}>
                {!!navLinks &&
                  navLinks[userData.type]?.secondaryNav
                    ?.filter((link) => !!link.isVisible)
                    ?.map((link, i) => (
                      <AuthDropdownItem
                        onMouseEnter={() => link.isComingSoon && setOpenChip(i)}
                        key={i}
                        ref={(ref) => (secondaryLinkRefs.current[i] = ref)}
                      >
                        {!!link.isComingSoon ? (
                          <NavbarLink tw="cursor-pointer flex items-center">
                            <Icon icon={link.icon} style={{ color: "#484848" }} height={24} tw="mb-5 mr-10" />{" "}
                            {link.label}
                          </NavbarLink>
                        ) : (
                          <NavbarLink as={Link} href={link.url} tw="flex items-center">
                            <Icon icon={link.icon} style={{ color: "#484848" }} height={24} tw="mb-5 mr-10" />{" "}
                            {link.label}
                          </NavbarLink>
                        )}
                        {link.isComingSoon && (
                          <AuthChipWrapper $isOpen={activeChip === i}>
                            <ComingSoonChip />
                          </AuthChipWrapper>
                        )}
                      </AuthDropdownItem>
                    ))}
                <AuthDropdownItem onClick={logout}>
                  <NavbarLink tw="flex items-center">
                    <Icon icon="simple-line-icons:logout" style={{ color: "#484848" }} height={25} tw="mb-5 mr-10" />
                    Logout
                  </NavbarLink>
                </AuthDropdownItem>
              </AuthDropdownWrapper>
            </AuthProfileIconWrapper>
          )}
        </RightContainer>
      </NavbarContent>
    </DesktopNav>
  );
};
