import React, { MouseEventHandler, MutableRefObject, RefObject } from "react";
import tw, { styled } from "twin.macro";
import { UserData } from "types";

import { Icon } from "@iconify/react";
import { useRouter } from "next/router";
import { enableBodyScroll } from "body-scroll-lock";
import {
  MobileNav,
  MenuContent,
  MenuButton,
  MenuContainer,
  ProfilePicture,
  BrandLink,
  AuthChipWrapper,
  navLinks,
  MobileUl,
  MobileLi,
  ItemDivider,
  AccountLinksWrapper,
  AccountLi,
  NavbarMobileContent,
  RightMobileContainer,
} from "./NavbarConstants";
import { Layouts } from "./Navbar";
import ComingSoonChip from "~/assets/icons/coming-soon-tooltip.svg";
import ProfileAvatarWhite from "~/assets/icons/avatar.svg";
import { Link, Button, SocialLinks } from "~/components/Base";
import LogoSVGWhite from "~/assets/logos/restworld-horizontal-white.svg";
import LogoSVGBlue from "~/assets/logos/restworld-horizontal-blue.svg";

interface MobileNavbarProps {
  isLg: boolean | undefined;
  isScrolled: boolean;
  layout: Layouts;
  isOpen: boolean;
  isPrivate: boolean;
  jwt?: string | null;
  userData?: UserData | null;
  isLoading: boolean;
  logout: MouseEventHandler<HTMLButtonElement>;
  activeChip: number | null;
  linkRefs: MutableRefObject<(HTMLLIElement | HTMLButtonElement | null)[]>;
  menuRef: RefObject<HTMLDivElement> | null;
  setIsOpen: Function;
  setOpenChip: Function;
  profileImg: string;
}

export const MobileNavbar = ({
  isLg,
  jwt,
  userData,
  logout,
  isLoading,
  isScrolled,
  isOpen,
  isPrivate,
  layout,
  activeChip,
  linkRefs,
  setIsOpen,
  setOpenChip,
  menuRef,
  profileImg,
}: MobileNavbarProps) => {
  const router = useRouter();
  return (
    <MobileNav
      $isScrolled={isScrolled}
      $layout={layout || "white"}
      $isOpen={isOpen}
      $isPrivate={isPrivate}
      $isXl={isLg}
      $isAuthenticated={!!userData}
    >
      <NavbarMobileContent $isScrolled={isScrolled} $isOpen={isOpen} $layout={layout || "white"}>
        <BrandLink
          href={
            !isPrivate
              ? "/"
              : userData?.type === "employer"
              ? "/employer/job-positions"
              : userData?.type === "worker"
              ? "/worker/dashboard"
              : "/"
          }
          tw="z-50"
        >
          {layout === "white" && !isOpen ? <LogoSVGBlue tw="h-30 lg:h-44" /> : <LogoSVGWhite tw="h-30 lg:h-44" />}
        </BrandLink>

        <RightMobileContainer>
          {/* profile image */}
          {!!userData ? (
            !!profileImg ? (
              <Link
                href={
                  userData.type === "employer"
                    ? "/employer/job-positions"
                    : userData.type === "worker"
                    ? "/worker/dashboard"
                    : "/"
                }
              >
                <ProfilePicture tw="mr-10 inline-block w-32 h-32" alt={userData.type} src={profileImg} />
              </Link>
            ) : (
              <ProfileAvatarWhite tw="mr-10 inline-block w-32 h-32" />
            )
          ) : null}

          {/* hamburger menu */}
          <MenuButton onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <Icon icon="ion:close-circle-outline" color="white" tw="w-36 h-36" />
            ) : layout === "white" ? (
              <Icon icon="charm:menu-hamburger" tw="w-36 h-36 " color="#365888" />
            ) : (
              <Icon icon="charm:menu-hamburger" tw="w-36 h-36 " />
            )}
          </MenuButton>
        </RightMobileContainer>
      </NavbarMobileContent>
      <MenuContainer $isOpen={isOpen} ref={menuRef}>
        <MenuContent $isPrivate={isPrivate}>
          {/* public items list */}
          <MobileUl>
            {(!userData || !isPrivate) &&
              !!navLinks &&
              navLinks.public?.primaryNav
                ?.filter((link) => !!link.isVisible)
                ?.map((link, i) => (
                  <>
                    <MobileLi
                      {...(!!link.isComingSoon ? { onClick: () => setOpenChip(i) } : undefined)}
                      {...(!!link.onClick ? { onClick: link.onClick } : undefined)}
                      key={i}
                      ref={(ref) => (linkRefs.current[i] = ref)}
                    >
                      {!!link.isComingSoon || !!link.onClick ? (
                        <>
                          <Icon icon={link.icon} height={24} tw="mb-5 mr-10" />
                          <Link href={link.url}>{link.label}</Link>
                        </>
                      ) : (
                        <>
                          <Icon icon={link.icon} height={24} tw="mb-5 mr-10" />
                          <Link href={link.url}>{link.label}</Link>
                        </>
                      )}
                      {link.isComingSoon && (
                        <AuthChipWrapper $isOpen={activeChip === i}>
                          <ComingSoonChip />
                        </AuthChipWrapper>
                      )}
                    </MobileLi>
                    {!!navLinks && navLinks.public?.primaryNav?.length !== i + 1 && i % 2 !== 0 && <ItemDivider />}
                  </>
                ))}
          </MobileUl>
          {!!userData && isPrivate && !!navLinks && (
            <>
              {/* private items list primaryNav */}
              <MobileUl>
                {navLinks[userData.type]?.primaryNav
                  ?.filter((link) => !!link.isVisible)
                  ?.map((link, i) => (
                    <MobileLi
                      {...(!!link.isComingSoon ? { onClick: () => setOpenChip(i) } : undefined)}
                      {...(!!link.onClick ? { onClick: link.onClick } : undefined)}
                      key={i}
                      ref={(ref) => (linkRefs.current[i] = ref)}
                    >
                      {!!link.isComingSoon || !!link.onClick ? (
                        <>
                          <Icon icon={link.icon} height={24} tw="mb-5 mr-10" />
                          <Link href={link.url}>{link.label}</Link>
                        </>
                      ) : (
                        <>
                          <Icon icon={link.icon} height={24} tw="mb-5 mr-10" />
                          <Link href={link.url}>{link.label}</Link>
                        </>
                      )}
                      {link.isComingSoon && (
                        <AuthChipWrapper $isOpen={activeChip === i}>
                          <ComingSoonChip />
                        </AuthChipWrapper>
                      )}
                    </MobileLi>
                  ))}
              </MobileUl>
              <ItemDivider />
              <AccountLinksWrapper>
                {/* private items list secondaryNav */}
                <MobileUl>
                  {navLinks[userData.type]?.secondaryNav
                    ?.filter((link) => !!link.isVisible)
                    ?.map((link, i) => (
                      <AccountLi
                        {...(!!link.isComingSoon ? { onClick: () => setOpenChip(i) } : undefined)}
                        {...(!!link.onClick ? { onClick: link.onClick } : undefined)}
                        key={i}
                        ref={(ref) => (linkRefs.current[i] = ref)}
                      >
                        <Icon icon={link.icon} height={24} tw="mb-5 mr-10" />
                        {!!link.isComingSoon || !!link.onClick ? (
                          <Link href={link.url}>{link.label}</Link>
                        ) : (
                          <Link href={link.url}>{link.label}</Link>
                        )}
                        {link.isComingSoon && (
                          <AuthChipWrapper $isOpen={activeChip === i}>
                            <ComingSoonChip />
                          </AuthChipWrapper>
                        )}
                      </AccountLi>
                    ))}
                </MobileUl>

                {!!userData
                  ? !!profileImg
                    ? isPrivate && <ProfilePicture tw="mr-10 inline-block" alt={userData.type} src={profileImg} />
                    : !isPrivate && <ProfileAvatarWhite tw="mr-10 inline-block" />
                  : null}

                <Button onClick={logout} $state="borderWhite">
                  Logout
                </Button>
              </AccountLinksWrapper>
            </>
          )}
          {/* profile image */}

          {!isPrivate && (
            <Button as={Link} href="/login">
              {isLoading ? "Accedi" : !!jwt && !!userData ? "Profilo" : "Accedi"}
            </Button>
          )}
        </MenuContent>
        <SocialLinks items={["facebook", "linkedin", "instagram"]} />
      </MenuContainer>
    </MobileNav>
  );
};
