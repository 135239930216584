import { useState, useEffect, useRef, useLayoutEffect } from "react";
import tw, { styled } from "twin.macro";
import { useRouter } from "next/router";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { MobileNavbar } from "./MobileNavbar";
import { DesktopNavbar } from "./DesktopNavbar";
import { useMediaQuery } from "~/hooks/useMediaQuery";
import { up } from "~/utils/screens";
import { useUserData } from "~/hooks/useUserData";
import useAuth from "~/hooks/useAuth";
import { useWorkerData } from "~/hooks/useWorkerData";
import { getRequest } from "~/utils/api";

interface NavbarProps {
  layout: Layouts;
}

export type Layouts =
  | "base"
  | "purple"
  | "white"
  | "gradientGreen"
  | "gradientDark"
  | "gradientBlue"
  | "gradientGreen2"
  | "gradientYellow"
  | "gradientPink"
  | "gradientRed"
  | "gradientPurple";

// navbar body
export const Navbar = ({ layout }: NavbarProps) => {
  const router = useRouter();
  const { jwt, user: userData, logout } = useAuth();
  const path = router.asPath.split("?")[0];
  const isLg = useMediaQuery(up("lg"));
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const privatePaths = ["/worker/", "/employer/", "/account/"];
  const isPrivate = privatePaths.filter((privatePath) => path.startsWith(privatePath)).length > 0;
  const isWhiteLayout: boolean = !!layout && layout === "white";
  const [activeChip, setActiveChip] = useState<number | null>(null);
  const dropdownRef = useRef(null);
  const linkRefs = useRef<(HTMLLIElement | HTMLButtonElement | null)[]>([]);
  const secondaryLinkRefs = useRef<(HTMLLIElement | HTMLButtonElement | null)[]>([]);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState<boolean>(false);
  const { isLoading, fetchUserData } = useUserData();
  const { workerData, fetchWorkerData } = useWorkerData();
  const assetUrl = workerData?.assets[0]?.url;

  //fetch the user Data
  useEffect(() => {
    !!fetchUserData && fetchUserData();
  }, [fetchUserData]);

  // fetch the worker data to extract the profile image url
  useEffect(() => {
    if (jwt) {
      !!fetchWorkerData && fetchWorkerData();
    }
  }, [fetchWorkerData, jwt]);

  // Close the nav when we navigate from a page to another
  useEffect(() => {
    router.events.on("routeChangeStart", () => setIsOpen(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this effect sets the hamburgher menu closed if the media query change
  useEffect(() => {
    if (isLg) setIsOpen(false);
  }, [isLg]);

  // disable navbar scroll in mobile
  useEffect(() => {
    if (!menuRef?.current) return;
    if (isOpen) {
      disableBodyScroll(menuRef.current);
    } else {
      enableBodyScroll(menuRef.current);
    }
  }, [isOpen]);

  // //sets the scroll position properly
  useScrollPosition(({ currPos }) => {
    setIsScrolled(Math.abs(currPos.y) > 20);
  });

  // sets the chip to be shown if needed
  let interval: NodeJS.Timeout;
  const setOpenChip = (index: number) => {
    clearTimeout(interval);
    setActiveChip(index);

    interval = setTimeout(() => {
      setActiveChip(null);
    }, 2000);
  };

  // logout method
  const performLogout = async () => {
    setIsOpen(false);
    if (!jwt) return;
    try {
      await getRequest(`/accounts/logout`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      await logout();
      router.replace("/");
    } catch (e) {
      console.error(e);
    }
  };

  /* useEffect that sets the dropdown menu close if */
  useEffect(() => {
    if (isLg) setIsProfileDropdownOpen(false);
  }, [isLg]);

  return isLg === undefined ? null : isLg ? (
    <DesktopNavbar
      jwt={jwt}
      userData={userData}
      logout={performLogout}
      isLoading={isLoading}
      isScrolled={isScrolled}
      isPrivate={isPrivate}
      isWhiteLayout={isWhiteLayout}
      layout={layout}
      activeChip={activeChip}
      linkRefs={linkRefs}
      setOpenChip={setOpenChip}
      isProfileDropdownOpen={isProfileDropdownOpen}
      setIsProfileDropdownOpen={setIsProfileDropdownOpen}
      dropdownRef={dropdownRef}
      secondaryLinkRefs={secondaryLinkRefs}
      profileImg={assetUrl}
      menuRef={menuRef}
    />
  ) : (
    <MobileNavbar
      isLg={isLg}
      jwt={jwt}
      userData={userData}
      logout={performLogout}
      isLoading={isLoading}
      isScrolled={isScrolled}
      isOpen={isOpen}
      isPrivate={isPrivate}
      layout={layout}
      activeChip={activeChip}
      linkRefs={linkRefs}
      setIsOpen={setIsOpen}
      setOpenChip={setOpenChip}
      menuRef={menuRef}
      profileImg={assetUrl}
    />
  );
};
